/* eslint-disable camelcase */
import { Table, TableProps, Tooltip } from 'antd';
import { Pagination, TenantUserList } from '../../../types';
import type { ColumnsType } from 'antd/es/table/interface';
import dayjs from 'dayjs';

interface Props {
  loading: boolean;
  userData: Array<TenantUserList>;
  onChange: (page?: number, limit?: number, sortBy?: string, orderBy?: string) => void;
  pagination: Pagination;
}

const UserListTable = ({ loading, userData, onChange, pagination }: Props) => {
  const columns: ColumnsType<TenantUserList> = [
    {
      key: 'user_name',
      title: 'Username',
      dataIndex: 'userName',

      sorter: (a, b) => {
        return 0;
      },
      width: 250,
      defaultSortOrder: 'ascend',
      ellipsis: { showTitle: false },
      render: (value) => (value?.length > 30 ? <Tooltip title={value}>{value}</Tooltip> : value),
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => {
        return 0;
      },
      width: 300,
      fixed: 'left',
      ellipsis: { showTitle: false },
      render: (value) => (value?.length > 40 ? <Tooltip title={value}>{value}</Tooltip> : value),
    },

    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => {
        return 0;
      },
    },
    {
      key: 'last_log_in',
      title: 'Last Login Time',
      dataIndex: 'lastLogIn',
      sorter: (a, b) => {
        return 0;
      },
      render: (value) =>
        !isNaN(Date.parse(value)) ? dayjs(value).format('DD MMM YYYY, hh:mm a') : '---',
    },
    {
      key: 'last_log_out',
      title: 'Last LogOut Time',
      dataIndex: 'lastLogOut',
      sorter: (a, b) => {
        return 0;
      },
      render: (value) =>
        !isNaN(Date.parse(value)) ? dayjs(value).format('DD MMM YYYY, hh:mm a') : '---',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => {
        return 0;
      },

      render: (value) => value?.toUpperCase(),
    },
  ];

  const handleOnChange: TableProps<TenantUserList>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    onChange(
      pagination?.current,
      pagination?.pageSize,
      Object(sorter)?.columnKey?.toString(),
      Object(sorter)?.order?.toString(),
    );
  };

  return (
    <>
      <div>
        <div className='flex justify-between items-center mb-4'>
          <div className='text-gray-500 font-bold'>User List Table</div>
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={userData}
        onChange={handleOnChange}
        size='small'
        pagination={{
          showTotal: (total) => `Total ${total} Users`,
          total: pagination?.totalRecords,
          defaultPageSize: 15,
          pageSizeOptions: [15, 30, 50, 100],
          current: pagination?.currentPage,
          showSizeChanger: true,
        }}
      />
    </>
  );
};
export default UserListTable;
