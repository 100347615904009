import axios, { AxiosRequestConfig } from 'axios';
import { CommonRequestQuery } from '../../types';
import {
  API_BASE_URL,
  FETCH_TENANTS_LIST,
  FETCH_TENANT_ATTENDANCE_TREND,
  FETCH_TENANT_USER_CREATION_TREND,
  FETCH_TENANT_USER_LIST,
} from '../../app/constants';
import { convertCamelToSnake } from '../../app/utils';

export const fetchTenantsThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANTS_LIST}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};

export const fetchExportTenantsThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANTS_LIST}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};

export const fetchTenantUserCreationTrendThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANT_USER_CREATION_TREND}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};

export const fetchTenantAttendanceTrendThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANT_ATTENDANCE_TREND}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};

export const fetchRAGOverviewThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANTS_LIST}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};

export const fetchRAG7TrendThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANTS_LIST}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};

export const fetchTenantUserListTrendThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANT_USER_LIST}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};

export const fetchSingleTenantThunk = (body: CommonRequestQuery) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${API_BASE_URL}${FETCH_TENANTS_LIST}`,
    data: convertCamelToSnake(body),
  };

  return axios(config);
};
