import { Progress } from 'antd';
import { licensesCoverageColors, licensesUsageColors } from '../../../app/utils';

interface Props {
  perValue?: number;
  name: string;
}

const ProgressPercentages = ({ perValue, name }: Props) => {
  return (
    <div className='rag-progress'>
      <Progress
        percent={perValue === 100 ? 99.99 : perValue}
        strokeColor={name === 'LU' ? licensesUsageColors : licensesCoverageColors}
      />
    </div>
  );
};

export default ProgressPercentages;
