import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Legend,
} from 'recharts';
import { TenantAttendanceTrend } from '../../../types';
import { Empty } from 'antd';
import { SkeletonLoader } from '../../../app/utils';

interface Props {
  data: Array<TenantAttendanceTrend>;
  maxTotalUsers: number | 0;
  maxAvgWorkingTime: number | 0;
  loading: boolean;
}

const AttendanceTrend = ({ data, maxTotalUsers, maxAvgWorkingTime, loading }: Props) => {
  return (
    <>
      <div className='flex justify-between items-center mb-4'>
        <div className='text-gray-500 font-bold'>Attendance Trend</div>
      </div>
      {loading ? (
        <SkeletonLoader />
      ) : data?.length === 0 ? (
        <Empty />
      ) : (
        <ResponsiveContainer width='100%' height='50%'>
          <ComposedChart
            width={400}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke='#f5f5f5' vertical={false} />
            <XAxis
              dataKey='fromDt'
              // interval={data.length - 2}
              tickLine={false}
              axisLine={{ stroke: '#f5f5f5' }}
            />
            <Tooltip />

            <Legend verticalAlign='top' align='center' height={36} iconType='circle' />

            <Bar
              dataKey='present'
              barSize={20}
              fill='#7B61FF'
              yAxisId='left'
              legendType='rect'
              name='Present'
            />
            <Bar
              dataKey='absent'
              barSize={20}
              fill='#CFD8DC'
              yAxisId='left'
              legendType='rect'
              name='Absent'
            />
            <Line
              dot={false}
              strokeWidth={3}
              strokeLinecap='round'
              type='monotone'
              dataKey='presentPercent'
              stroke='#FFB800'
              yAxisId='right'
              legendType='rect'
              name='Attendance %'
            />
            <Line
              dot={false}
              strokeWidth={2}
              strokeLinecap='round'
              type='monotone'
              dataKey='avgWorkingTime'
              stroke='#00B5B5'
              yAxisId='right2'
              legendType='rect'
              name={'Avg working hours'}
            />
            <YAxis
              tickLine={true}
              yAxisId='left'
              axisLine={{ stroke: '#f5f5f5' }}
              domain={[0, maxTotalUsers + 5]}
              tickCount={6}
            />
            <YAxis
              tickLine={true}
              yAxisId='right'
              orientation='right'
              stroke='#FFB800'
              axisLine={{ stroke: '#f5f5f5' }}
              unit='%'
              domain={[0, 100]}
              tickCount={6}
              width={80}
              label={{
                value: 'Attendance %',
                angle: -90,
                position: 'center',
                offset: '20',
              }}
            />

            <YAxis
              tickLine={true}
              yAxisId='right2'
              orientation='right'
              stroke='#00B5B5'
              axisLine={{ stroke: '#f5f5f5' }}
              domain={[0, maxAvgWorkingTime + 5]}
              tickCount={6}
              width={80}
              label={{
                value: 'Avg working hours',
                angle: -90,
                position: 'center',
                offset: '20',
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default AttendanceTrend;
