import { Tag, Tooltip, Skeleton } from 'antd';
import ProgresPercents from './ProgressPercentages';
import { RAG7Trend, Tenant } from '../../../types';
import { BarChartOutlined } from '@ant-design/icons';
import { LicenseSkeleton, copyToClipboard, getPercentage } from '../../../app/utils';

import dayjs from 'dayjs';
interface Props {
  basicInfo: Tenant;
  basicInfoLoading: boolean;
  currentRAGStatus: Array<RAG7Trend>;
  loading: boolean;
}
import logo from '../../../apple-touch-icon.png';

const TenantInfo = ({ basicInfo, currentRAGStatus, loading, basicInfoLoading }: Props) => {
  return (
    <div>
      <div className='grid grid-cols-6 gap-4'>
        <div className='col-span-2 w-full relative max-w-sm mx-auto   ring-black/5 rounded-xl flex items-start gap-6 dark:bg-slate-800 dark:highlight-white/5'>
          <img className='absolute mt-3 w-20 h-20 rounded-full' src={logo} />
          <div className='flex flex-col py-5 pl-24 '>
            <strong className='text-slate-900 text-sm font-medium dark:text-slate-200'>
              {basicInfoLoading ? (
                <Skeleton.Input active={true} size='small' className='w-56 h-5 mt-1 ' />
              ) : (
                basicInfo.name
              )}
            </strong>
            <span className='text-slate-500 text-sm font-medium dark:text-slate-400'>
              {basicInfoLoading ? (
                <Skeleton.Input active={true} size='small' className='w-40 h-5 mt-1' />
              ) : (
                basicInfo?.baseDomain
              )}
            </span>
            <span className='text-slate-500 text-sm font-medium dark:text-slate-400'>
              {basicInfoLoading ? (
                <Skeleton.Input active={true} size='small' className='w-64 h-5 mt-1' />
              ) : (
                basicInfo?.contactEmail
              )}
            </span>
          </div>
        </div>

        <div className='flex flex-col py-5 '>
          <div className='pb-2'>Tenant ID</div>

          <div className='content-center cursor-pointer'>
            {basicInfoLoading ? (
              <Skeleton.Input active={true} size='default' />
            ) : (
              <Tooltip title={`Click to copy tenant ID`}>
                <Tag
                  className='w-48 h-8 flex items-center justify-center'
                  onClick={() => copyToClipboard(`${basicInfo?.id?.replaceAll('_', '-')}`)}
                >
                  {basicInfo?.id?.replaceAll('_', '-')}
                </Tag>
              </Tooltip>
            )}
          </div>
        </div>
        <div>
          <div className='flex flex-col py-5'>
            <div className='pb-2'>Recently RAG Status</div>
            <div className='content-center'>
              {loading ? (
                <Skeleton.Input active={true} size='default' />
              ) : (
                currentRAGStatus?.map((element, index) => {
                  if (index === 0) {
                    return (
                      <Tag
                        key={index}
                        color={
                          element?.ragStatus === 'RED'
                            ? '#f43f5e'
                            : element?.ragStatus === 'AMBER'
                            ? '#f59e0b'
                            : '#0d9488'
                        }
                        className='w-48 h-8 flex items-center justify-center '
                      >
                        {dayjs(element?.day).format('DD MMM')}
                      </Tag>
                    );
                  }
                })
              )}
            </div>
          </div>
        </div>

        <div className='bg-white flex items-start rounded-[6px] p-2 w-full border border-gray-200'>
          <div className='flex mr-3 mt-[2px] bg-green-200 rounded-[6px] p-[6px]'>
            <BarChartOutlined className='text-green-600 text-[15px]' />
          </div>

          <div className='flex flex-col  w-full'>
            <div>Created licenses</div>
            {basicInfoLoading ? (
              <LicenseSkeleton />
            ) : (
              <>
                <span className='text-black font-bold text-lg'>{`${basicInfo?.loginCount} / ${basicInfo?.createdLiecense}`}</span>
                <ProgresPercents
                  name='LU'
                  perValue={getPercentage(basicInfo?.loginCount, basicInfo?.createdLiecense)}
                />
              </>
            )}
          </div>
        </div>

        <div className='bg-white flex items-start rounded-[6px] p-2 w-full border border-gray-200'>
          <div className='flex mr-3 mt-[2px] bg-green-200 rounded-[6px] p-[6px]'>
            <BarChartOutlined className='text-green-600 text-[15px]' />
          </div>

          <div className='flex flex-col  w-full'>
            <div>Allowed licenses</div>
            {basicInfoLoading ? (
              <LicenseSkeleton />
            ) : (
              <>
                <span className='text-black font-bold text-lg'>{`${basicInfo?.loginCount} / ${basicInfo?.allowedLiecence}`}</span>
                <ProgresPercents
                  name='LC'
                  perValue={getPercentage(basicInfo?.loginCount, basicInfo?.allowedLiecence)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantInfo;
