import { message, Skeleton, Space } from 'antd';
import { PieChartOutlined, DotChartOutlined } from '@ant-design/icons';

export const convertSnakeToCamel = <T extends Record<string, any>>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertSnakeToCamel(item)) as any;
  } else if (typeof obj === 'object' && obj !== null) {
    const camelObj: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      camelObj[camelKey] = convertSnakeToCamel(value);
    }
    return camelObj as T;
  }
  return obj;
};

export const convertCamelToSnake = <T extends Record<string, any>>(obj: T): T =>
  Object.entries(obj).reduce((snakeObj, [key, value]) => {
    const snakeKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    if (Array.isArray(value)) {
      value = value.map((item) => convertCamelToSnake(item));
    } else if (typeof value === 'object' && value !== null) {
      value = convertCamelToSnake(value);
    }
    return { ...snakeObj, [snakeKey]: value };
  }, {} as T);

export const copyToClipboard = (text: string, showMessage = true) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.select();

  try {
    const successful = document.execCommand('copy');
    const textMessage = successful ? 'URL copied to clipboard' : 'Unable to copy URL to clipboard';
    if (showMessage) {
      message.success(textMessage);
    }
  } catch (error) {
    console.error('Error copying text to clipboard:', error);
  }

  document.body.removeChild(textarea);
};

export const exportCSV = (filename: string, data: any[]) => {
  const csvContent = data.join('\n');
  const csvBlob = new Blob([csvContent], { type: 'text/csv' });
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(csvBlob);
  downloadLink.download = filename;
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getPercentage = (num1?: number, num2?: number) => {
  if (!num1 || !num2) {
    return 0;
  }

  return parseFloat(((num1 / num2) * 100).toFixed(2));
};

export const chartColorArray = [
  { name: 'LU', color: '#c7d2fe' },
  { name: 'LU', color: '#818cf8' },
  { name: 'LU', color: '#6366f1' },
  { name: 'LU', color: '#4338ca' },
  { name: 'LC', color: '#86efac' },
  { name: 'LC', color: '#4ade80' },
  { name: 'LC', color: '#22c55e' },
  { name: 'LC', color: '#16a34a' },
];

export const licensesUsageColors = {
  '0%': '#93c5fd',
  '25%': '#60a5fa',
  '50': '#3b82f6',
  '100%': '#2563eb',
};
export const licensesCoverageColors = {
  '0%': '#86efac',
  '25%': '#4ade80',
  '50': '#22c55e',
  '100%': '#16a34a',
};

export const ragOption = [
  { value: 'license_uses_per', label: 'RAG via Created Licenses % (L/C)' },
  { value: 'license_coverage_per', label: 'RAG via Allowed Licenses % (L/A)' },
];

export const allowedOption = [
  { value: '0-5', label: '0-5' },
  { value: '6-20', label: '6-20' },
  { value: '21-50', label: '21-50' },
  { value: '51-100', label: '51-100' },
  { value: '101-50000', label: '100+' },
];

export const activeDeactiveOption = [
  { value: 'ENABLED', label: 'Active' },
  { value: 'DISABLED', label: 'Deactive' },
];

export const getChartData = (value: string) => {
  return chartColorArray
    .filter((item) => item.name === value)
    .map((item, index) => ({
      name: item.name + index,
      value: 25,
      color: item.color,
    }));
};

export const ProgressPercentageSkeleton = () => {
  return (
    <div>
      <Skeleton.Input size={'small'} className='w-80 h-5' />
      <div className='mt-2'>
        <Skeleton.Button size={'large'} className='w-24 h-11' />
      </div>
      {/* <Space className='mt-2'>
        <Skeleton.Input active={true} size={'small'} className='w-96 h-5' />
        <Skeleton.Button
          active={true}
          size={'small'}
          shape={'default'}
          block={false}
          className='h-5'
        />
      </Space> */}
    </div>
  );
};

export const PieNeedleRechartsSkeleton = () => {
  return (
    <div>
      <Space className='mt-2'>
        <Skeleton.Input active={true} size={'small'} className='w-20 h-5 mr-80' />
        <Skeleton.Avatar active={true} size={'small'} shape='circle' />
      </Space>
      <Skeleton.Input size={'small'} className='w-80 h-5 mt-1' />
      <div className='mt-4 max-w-36'>
        <Skeleton.Node active={true} className='w-full h-40'>
          <PieChartOutlined className='text-8xl text-gray-400' />
        </Skeleton.Node>
      </div>
    </div>
  );
};

export const RAGCountSkeleton = () => {
  return (
    <div>
      <Space className='mt-2'>
        <Skeleton.Button active={true} size={'small'} className='w-20 h-5 mr-44' />
        <Skeleton.Button
          active={true}
          size={'small'}
          shape={'default'}
          block={false}
          className='h-5'
        />
      </Space>
      <div>
        <Skeleton.Input size={'small'} className='w-52 h-5 mt-1' />
      </div>
    </div>
  );
};

export const SkeletonLoader = () => {
  return (
    <Skeleton.Node active={true} className=' w-full h-80'>
      <DotChartOutlined style={{ fontSize: 200, color: '#bfbfbf' }} />
    </Skeleton.Node>
  );
};

export const RAG7TrendSkeleton = () => {
  return (
    <div>
      <Space className='mt-2 '>
        <Skeleton.Button
          active={true}
          size={'small'}
          shape={'default'}
          block={false}
          className='h-5 w-20'
        />
      </Space>
      <div>
        <Skeleton.Input size={'small'} className='w-40 h-7 mt-1' />
      </div>
    </div>
  );
};

export const LicenseSkeleton = () => {
  return (
    <div>
      <Space className='mt-1'>
        <Skeleton.Button
          active={true}
          size={'small'}
          shape={'default'}
          block={false}
          className='h-5 w-16'
        />
      </Space>
      <Space className='mt-1'>
        <Skeleton.Button
          active={true}
          size={'small'}
          shape={'default'}
          block={false}
          className='h-5 w-20'
        />
        <Skeleton.Button active={true} size={'small'} className='w-3 h-5' />
      </Space>
    </div>
  );
};
