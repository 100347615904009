/* eslint-disable camelcase */
import React from 'react';
import { Pagination, RAGOverviewTrend, Tenant } from '../../types';
import dayjs, { Dayjs } from 'dayjs';

export const initialPagination: Pagination = {
  totalRecords: 0,
  currentPage: 1,
  limit: 0,
  prePage: 1,
  nextPage: 0,
  leftPage: 0,
  optional: 0,
};

export const rangePresets: Array<{ label: React.ReactNode; value: [Dayjs, Dayjs] }> = [
  {
    label: 'Today',
    value: [dayjs(), dayjs()],
  },
  {
    label: 'Yestaday',
    value: [dayjs().subtract(1, 'd'), dayjs().subtract(1, 'd')],
  },
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
  // {
  //   label: 'Last 180 Days',
  //   value: [dayjs().add(-180, 'd'), dayjs()],
  // },
  // {
  //   label: '1 Year',
  //   value: [dayjs().add(-1, 'y'), dayjs()],
  // },
  // {
  //   label: '2 Year',
  //   value: [dayjs().add(-2, 'y'), dayjs()],
  // },
];

export const initialRAGOverviewData: RAGOverviewTrend = {
  redCount: 0,
  amberCount: 0,
  greenCount: 0,
  allCount: 0,
  loginCount: 0,
  allowedLiecence: 0,
  createdLiecense: 0,
  licenseUsesPer: 0,
  licenseCovragePer: 0,
};

export const initialSingleTrendData: Tenant = {
  id: '',
  name: '',
  contactEmail: '',
  allowedLiecence: 0,
  createdLiecense: 0,
  loginCount: 0,
  lastLoginDate: '',
  licenseUsesPer: 0,
  licenseCovragePer: 0,
  ragStatus: null,
  status: '',
  currentPlan: '',
  createdDate: '',
  baseDomain: '',
  phoneNumber: '',
  deletedDate: '',
};
