/* eslint-disable camelcase */
import { Tag } from 'antd';
import { RAG7Trend } from '../../../types';
import { RAG7TrendSkeleton } from '../../../app/utils';
import dayjs from 'dayjs';
interface Props {
  ragData: Array<RAG7Trend>;
  loading: boolean;
}

const RAGTrend = ({ ragData, loading }: Props) => {
  return (
    <div>
      <span className='text-gray-500 font-bold'>7 Day RAG Trend</span>
      <div className='grid grid-cols-7 gap-4'>
        {loading
          ? Array(7)
              .fill('value')
              .map((item, index) => {
                return <RAG7TrendSkeleton key={index} />;
              })
          : ragData?.map((element, index) => (
              <div className='flex flex-col py-5' key={index}>
                <div className='text-center'>
                  {dayjs(element?.day).format('DD MMM')}
                  <Tag
                    color={
                      element?.ragStatus === 'RED'
                        ? '#f43f5e'
                        : element?.ragStatus === 'AMBER'
                        ? '#f59e0b'
                        : '#0d9488'
                    }
                    className='w-full   h-8 flex items-center justify-center '
                  >{`${!element?.percentage ? 0 : element?.percentage}%`}</Tag>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default RAGTrend;
