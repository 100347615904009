import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import { TenantUserCreationTrend } from '../../../types';
import { Empty, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { SkeletonLoader } from '../../../app/utils';

interface Props {
  tenantUserCreationTrend: Array<TenantUserCreationTrend>;
  maxCount: number | 0;
  loading: boolean;
}

const WorkingTimeTrend = ({ tenantUserCreationTrend, maxCount, loading }: Props) => {
  return (
    <>
      <div className='flex justify-between items-center mb-4'>
        <div className='text-gray-500 font-bold'>User Creation Trend</div>
        <div className='text-gray-500 font-bold'>
          {`Total Created Users: `}
          {loading ? (
            <Skeleton.Button active className='w-1 h-7 ml-1' />
          ) : (
            tenantUserCreationTrend.reduce((acc, data) => acc + data.count, 0)
          )}
        </div>
      </div>
      {loading ? (
        <SkeletonLoader />
      ) : tenantUserCreationTrend?.length === 0 ? (
        <Empty />
      ) : (
        <ResponsiveContainer width='100%' height='50%'>
          <ComposedChart
            width={390}
            height={300}
            data={tenantUserCreationTrend.map((data) => ({
              name: (
                dayjs(data.createdDate).format('DD-MM-YYYY') || new Date().toISOString()
              ).split('T')[0],
              createdUserCount: data.count,
            }))}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis domain={[0, maxCount + 4]} tickCount={5} />
            <Tooltip />
            <Line
              type='monotone'
              dataKey='createdUserCount'
              stroke='#82ca9d'
              label={'Created Users'}
              name='Create User Count'
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default WorkingTimeTrend;
