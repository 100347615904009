import React from 'react';
import { FilterFilled } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { rangePresets } from '../../../app/constants';
import { SelectValue } from 'antd/lib/select';
import { DateRange, Status } from '../../../types';
import { activeDeactiveOption, allowedOption, ragOption } from '../../../app/utils';

interface Props {
  onDateChange: (range: DateRange) => void;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onChangeCriteria: (value: SelectValue) => void;
  onChangeAllowedLicenses: (value: SelectValue) => void;
  onClear: () => void;
  criteriaValue: SelectValue;
  allowLicensesValue: SelectValue;
  onChangeStatus: (value: Status) => void;
  tenantstatus?: Status;
}

const Filter = ({
  onDateChange,
  startDate,
  endDate,
  onChangeCriteria,
  onChangeAllowedLicenses,
  onClear,
  criteriaValue,
  allowLicensesValue,
  onChangeStatus,
  tenantstatus,
}: Props) => {
  function disabledDate(current: Dayjs) {
    const weekStart = dayjs().subtract(5, 'year');
    const weekEnd = dayjs();
    return !(weekStart.isBefore(current) && weekEnd.isAfter(current));
  }

  const btnOnClear = () => {
    onClear();
  };

  return (
    <div className={`bg-white flex  justify-between items-center gap-5 text-2xl mb-4 h-14 `}>
      <div className='flex justify-start items-center gap-5'>
        <FilterFilled style={{ fontSize: '16px' }} className='ml-5' />
        <div>
          <DatePicker.RangePicker
            defaultValue={[startDate, endDate]}
            onCalendarChange={(e) => {
              const sDate = e?.[0];
              const eDate = e?.[1];
              onDateChange({ startDate: sDate, endDate: eDate });
            }}
            presets={rangePresets}
            disabledDate={disabledDate}
            value={[startDate, endDate]}
            allowClear={false}
          />
        </div>
        <div>
          <Select
            className='w-64'
            options={ragOption}
            placeholder='RAG Criteria'
            onChange={onChangeCriteria}
            value={criteriaValue === '' ? '' : criteriaValue}
          />
        </div>
        <div>
          <Select
            className='w-48'
            options={allowedOption}
            placeholder='Allowed Licenses'
            allowClear
            onChange={onChangeAllowedLicenses}
            value={allowLicensesValue === '' ? [] : allowLicensesValue}
          />
        </div>
        <div>
          <Select
            className='w-48'
            options={activeDeactiveOption}
            placeholder='Active/Deactive Tenant'
            allowClear
            onChange={onChangeStatus}
            value={tenantstatus === '' ? null : tenantstatus}
          />
        </div>
      </div>

      <div className='items-end pr-4'>
        <div>
          <Button value={'Clear filter'} type='text' className='' onClick={btnOnClear}>
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
