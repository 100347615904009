/* eslint-disable camelcase */
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { fetchTenantUserList, selectTenants } from '../tenantsSlice';
import { Divider } from 'antd';
import AttendanceTrend from './AttendanceTrend';
import dayjs from 'dayjs';
import { onlyHoursNumber } from '../../../timeConverter';
import TenantInfo from './TenantInfo';
import RAGTrend from './RAGTrend';
import CreationTrend from './CreationTrend';
import WorkingTimeTrend from './WorkingTimeTrend';
import UserListTable from './UserListTable';
import { Tenant } from '../../../types';

interface Props {
  tenantDetails?: Tenant | null | undefined;
}

const TenantDrawer = ({ tenantDetails }: Props) => {
  const dispatch = useAppDispatch();
  const {
    fetchTenantUserCreationTrendLoading,
    tenantUserCreationTrend,
    fetchTenantAttendanceTrendLoading,
    tenantAttendanceTrend,
    fetchRAG7TrendLoading,
    fetchRAG7TrendData,
    fetchTenantUserListLoading,
    fetchTenantUserListData,
    fetchTenantUserListPagination,
    fetchSingleTrendData,
    fetchSingleTrendLoading,
  } = useAppSelector(selectTenants);

  const generateDate = () => {
    return tenantAttendanceTrend.map((data, i) => {
      const date = dayjs(data.fromDt).format('DD/MM');
      const AttendancePer = parseFloat(data?.presentPercent?.toFixed(2));
      const avgWorkingTime = onlyHoursNumber(data?.avgWorkingTime?.toFixed(2));

      return {
        fromDt: date,
        index: i,
        present: data.present,
        absent: data.absent,
        presentPercent: AttendancePer,
        avgWorkingTime: avgWorkingTime,
        avgOnlineDuration: data.avgOnlineDuration,
        avgPunchDuration: data.avgPunchDuration,
        onlineDuration: data.onlineDuration,
        punchDuration: data.punchDuration,
        totalUser: data.totalUser,
        workingTime: data.workingTime,
        absentPercent: data.absentPercent,
      };
    });
  };

  const attendanceTrendData = generateDate();
  const maxTotalUsers = Math.max(...attendanceTrendData.map((item) => item.totalUser));
  const maxAvgWorkingTime = Math.max(...attendanceTrendData.map((item) => item.avgWorkingTime));
  const maxWorkingTime =
    attendanceTrendData?.length > 0
      ? parseInt(Math.max(...attendanceTrendData.map((item) => item.workingTime / 3600)).toFixed(0))
      : 0;

  const totalCount =
    tenantUserCreationTrend?.length > 0 ? tenantUserCreationTrend?.map((item) => item.count) : [];
  const maxCount = totalCount?.length > 0 ? Math.max(...totalCount) : 0;

  const handleUserTableOnChange = (
    page?: number,
    limit?: number,
    sortBy?: string,
    orderBy?: string,
  ) => {
    dispatch(
      fetchTenantUserList({
        limit: limit,
        page: page,
        tenant_id: tenantDetails?.id?.replaceAll('_', '-'),
        startDate: dayjs().subtract(29, 'd').format('YYYY-MM-DD') + 'T00:00:00.034Z',
        endDate: dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z',
        sort_by: sortBy,
        desc: orderBy === 'ascend' ? false : true,
      }),
    );
  };

  return (
    <>
      <TenantInfo
        basicInfo={fetchSingleTrendData}
        basicInfoLoading={fetchSingleTrendLoading}
        currentRAGStatus={fetchRAG7TrendData}
        loading={fetchRAG7TrendLoading}
      />
      <Divider />
      <RAGTrend ragData={fetchRAG7TrendData} loading={fetchRAG7TrendLoading} />
      <Divider />
      <AttendanceTrend
        data={attendanceTrendData}
        maxAvgWorkingTime={maxAvgWorkingTime}
        maxTotalUsers={maxTotalUsers}
        loading={fetchTenantAttendanceTrendLoading}
      />
      <Divider />
      <CreationTrend
        tenantUserCreationTrend={tenantUserCreationTrend}
        maxCount={maxCount}
        loading={fetchTenantUserCreationTrendLoading}
      />
      <Divider />
      <WorkingTimeTrend
        tenantAttendanceTrend={tenantAttendanceTrend}
        maxWorkingTime={maxWorkingTime}
        loading={fetchTenantAttendanceTrendLoading}
      />
      <Divider />
      <UserListTable
        loading={fetchTenantUserListLoading}
        userData={fetchTenantUserListData}
        onChange={handleUserTableOnChange}
        pagination={fetchTenantUserListPagination}
      />
    </>
  );
};

export default TenantDrawer;
