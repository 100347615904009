import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';

import { TenantAttendanceTrend } from '../../../types';
import { Empty } from 'antd';
import { onlyHoursNumber } from '../../../timeConverter';
import dayjs from 'dayjs';
import { SkeletonLoader } from '../../../app/utils';

interface Props {
  tenantAttendanceTrend: Array<TenantAttendanceTrend>;
  maxWorkingTime: number | 0;
  loading: boolean;
}

const WorkingTimeTrend = ({ tenantAttendanceTrend, maxWorkingTime, loading }: Props) => {
  return (
    <>
      <div className='flex justify-between items-center mb-4'>
        <div className='text-gray-500 font-bold'>Working Time Trend</div>
      </div>
      {loading ? (
        <SkeletonLoader />
      ) : tenantAttendanceTrend?.length === 0 ? (
        <Empty />
      ) : (
        <ResponsiveContainer width='100%' height='50%'>
          <ComposedChart
            width={390}
            height={300}
            data={tenantAttendanceTrend.map((data) => ({
              name: (dayjs(data.fromDt).format('DD/MM') || new Date().toISOString()).split('T')[0],
              totalWorkingTime: onlyHoursNumber((data.workingTime || 0).toFixed()),
            }))}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis domain={[0, maxWorkingTime + 10]} tickCount={5} />
            <Tooltip />
            <Line
              type='monotone'
              dataKey='totalWorkingTime'
              stroke='navy'
              label={'Total Working Time'}
              name='Total Working Time'
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default WorkingTimeTrend;
