/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { LayoutHOC } from '../Common';
import { DateRange, RAGStatus, Status, Tenant } from '../../types';
import { Drawer, Select, Space, Tooltip, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  fetchExportTenants,
  fetchTenants,
  selectTenants,
  setTenantsErrorMessage,
  fetchRAGOverview,
  fetchRAG7Trend,
  setResetTenantsExport,
} from './tenantsSlice';
import TenantDrawer from './components/TenantDrawer';
import dayjs, { Dayjs } from 'dayjs';
import Filter from './components/Filters';

import PieNeedleRecharts from './components/PieNeedleRechart';
import RAGDataCount from './components/RAGDataCount';
import { InfoCircleFilled } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import TenantList from './components/TenantList';

import {
  exportCSV,
  getChartData,
  getPercentage,
  PieNeedleRechartsSkeleton,
  ProgressPercentageSkeleton,
  RAGCountSkeleton,
  ragOption,
} from '../../app/utils';

const Tenants = () => {
  const dispatch = useAppDispatch();

  const {
    fetchTenantsLoading,
    tenantsData,
    tenantsErrorMessage,
    tenantTotalRecords,
    fetchRAGOverviewData,
    fetchRAGOverviewLoading,
    fetchExportTenantsLoading,
    tenantsExportErrorMessage,
    fetchExportTenantsSuccessfully,
    tenantsExportData,
  } = useAppSelector(selectTenants);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [nameSearch, setNameSearch] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(7, 'd'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [criteria, setCriteria] = useState<SelectValue>('license_uses_per');
  const [allowLicenses, setAllowLicenses] = useState<SelectValue>('');
  const [tenantDetails, setTenantDetails] = useState<Tenant | null>(null);
  const [tenantstatus, setTenantStatus] = useState<Status>('');
  const [ragStatus, setRAGStatus] = useState<RAGStatus>('');
  const [actionType, setActionType] = useState('pageLoad');
  const [singleTenantCriteria, setSingleTenantCriteria] = useState<SelectValue>('license_uses_per');
  const [isClear, setIsClear] = useState(false);
  const getParameters = () => {
    let result = {};
    result = {
      page: page,
      limit: limit,
      start_date: dayjs(startDate).format('YYYY-MM-DD') + 'T00:00:00.034Z',
      end_date: dayjs(endDate).format('YYYY-MM-DD') + 'T23:59:59.034Z',
      name: '',
      status: tenantstatus,
      rag_type: criteria,
      allow_user: allowLicenses,
      rag_status: ragStatus,
      rag_summary: false,
      download: false,
    };

    return result;
  };

  const getTenantsData = (search?: string, type?: string) => {
    const name = !search ? nameSearch : search;
    const aType = !type ? actionType : type;
    const start_date = name
      ? dayjs().subtract(5, 'y').format('YYYY-MM-DD') + 'T00:00:00.034Z'
      : dayjs(startDate).format('YYYY-MM-DD') + 'T00:00:00.034Z';
    const end_date = name
      ? dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z'
      : dayjs(endDate).format('YYYY-MM-DD') + 'T23:59:59.034Z';

    dispatch(fetchTenants({ ...getParameters(), name, start_date, end_date }));

    if (aType !== 'pagination' && aType !== 'ragStatus') {
      const page = 1;
      const limit = 0;
      const rag_summary = true;
      dispatch(
        fetchRAGOverview({
          ...getParameters(),
          name,
          start_date,
          end_date,
          rag_summary,
          page,
          limit,
        }),
      );
    }
  };

  useEffect(() => {
    getTenantsData('', actionType);
  }, [
    actionType,
    startDate,
    endDate,
    criteria,
    allowLicenses,
    page,
    limit,
    tenantstatus,
    ragStatus,
  ]);

  useEffect(() => {
    if (tenantsErrorMessage) {
      notification.error({ message: 'Uh Oh!', description: tenantsErrorMessage });
      dispatch(setTenantsErrorMessage(''));
    }
    if (tenantsExportErrorMessage) {
      notification.error({ message: 'Uh Oh!', description: tenantsExportErrorMessage });
      dispatch(setResetTenantsExport(''));
    }
  }, [tenantsErrorMessage, tenantsExportErrorMessage]);

  const onPaginationChange = (page: number, limit: number, actionType: string) => {
    setPage(page);
    setLimit(limit);
    setActionType(actionType);
  };

  const handleCancel = () => {
    setTenantDetails(null);
    setSingleTenantCriteria('license_uses_per');
  };

  const setSearchString = (searchValue: string) => {
    setNameSearch(searchValue);
    setPage(1);
    setLimit(20);
    setTenantStatus('');
    setAllowLicenses('');
    setRAGStatus('');
    if (searchValue?.length > 2) {
      setStartDate(null);
      setEndDate(null);
      setActionType('searching');
      getTenantsData(searchValue, 'searching');
    } else if (searchValue?.length === 0) {
      setActionType('pageLoad');
      setStartDate(dayjs().subtract(7, 'd'));
      setEndDate(dayjs());
    }
  };

  const onDateChange = (range: DateRange) => {
    const dateDiff = !range ? 0 : dayjs(range.endDate).diff(range.startDate, 'd');
    if (dateDiff <= 90) {
      setActionType('datePicker');
      setRAGStatus('');
      setPage(1);
      setLimit(20);
      setNameSearch('');
      if (range) {
        setStartDate(range.startDate);
        setEndDate(range.endDate);
      } else {
        setStartDate(dayjs().subtract(7, 'd'));
        setEndDate(dayjs());
      }
    } else {
      notification.warning({
        message: 'Date Range',
        description: 'The maximum allowed date range is 90 days.',
      });
    }
  };

  const onChangeCriteria = (value: SelectValue) => {
    setActionType('criteria');
    setCriteria(value);
    setRAGStatus('');
    setPage(1);
    setLimit(20);
  };
  const onChangeAllowedLicenses = (value: SelectValue) => {
    setActionType('allowedLicenses');
    setAllowLicenses(value);
    setRAGStatus('');
    setPage(1);
    setLimit(20);
  };

  const onClear = () => {
    setIsClear(!isClear);
    setActionType('reset');
    setNameSearch('');
    setCriteria('license_uses_per');
    setStartDate(dayjs().subtract(7, 'd'));
    setEndDate(dayjs());
    setRAGStatus('');
    setPage(1);
    setLimit(20);
    setTenantStatus('');
    setAllowLicenses('');
  };

  const onChangeStatus = (value: Status) => {
    setActionType('tenantStatus');
    setTenantStatus(value);
    setRAGStatus('');
    setPage(1);
    setLimit(20);
  };

  const onClickRAGStatus = (value: RAGStatus) => {
    setActionType('ragStatus');
    setRAGStatus(value);
    setPage(1);
    setLimit(20);
  };

  const getTenantsExportData = () => {
    const limit = 0;
    const page = 1;
    const download = true;
    const name = nameSearch;
    const start_date = name
      ? dayjs().subtract(5, 'y').format('YYYY-MM-DD') + 'T00:00:00.034Z'
      : dayjs(startDate).format('YYYY-MM-DD') + 'T00:00:00.034Z';
    const end_date = name
      ? dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z'
      : dayjs(endDate).format('YYYY-MM-DD') + 'T23:59:59.034Z';

    dispatch(
      fetchExportTenants({ ...getParameters(), name, page, limit, start_date, end_date, download }),
    );
  };

  const onChangeCriteriaSingleTenant = (e: SelectValue) => {
    const criteria = !e ? '' : e;
    setSingleTenantCriteria(criteria);
    dispatch(
      fetchRAG7Trend({
        limit: 0,
        page: 1,
        rag_type: criteria?.toString(),
        rag_summary: false,
        tenant_id: tenantDetails?.id?.replaceAll('_', '-'),
        startDate: dayjs(startDate).format('YYYY-MM-DD') + 'T00:00:00.034Z',
        endDate: dayjs(endDate).format('YYYY-MM-DD') + 'T23:59:59.034Z',
      }),
    );
  };

  const exportcsv = () => {
    const fileName = 'Tenant List';
    const saveFileName = 'Tenant_list_' + dayjs().format('DD_MM_YYYY_hh_mm_ss');
    const generatedDate = 'Generated Date:-' + dayjs().format('DD-MM-YYYY hh:mm a');

    const dateRange = dayjs(startDate).isValid()
      ? dayjs(startDate).format('YYYY-MM-DD') + '--' + dayjs(endDate).format('YYYY-MM-DD')
      : '';

    const criteriaValue =
      criteria === 'license_uses_per'
        ? 'RAG via Created Licenses % (L/C)'
        : 'RAG via Allowed Licenses % (L/A)';

    const filterData = `Filter :-, Search text :${
      nameSearch === '' ? '' : nameSearch
    } , Daterange : ${dateRange} , Criteria : ${criteriaValue} , Allowed Licenses : ${allowLicenses} , Active / Deactive : ${tenantstatus}  , RAG Status : ${ragStatus}`;

    const colunms = [
      'Domain name',
      'Email ID',
      'Tenant ID',
      'Name',
      'Plan',
      'Allowed Licenses (A)',
      'Created Licenses (C)',
      'Login Count (L)',
      'Last Login Date',
      'Created Date',
      'Active Days',
      'RAG via Allowed Licenses % (L/A)',
      'RAG via Created licenses % (L/C)',
      'RAG Status',
      'Status',
    ];

    const csvData: any[] = [];
    const firstNewLineIndex = tenantsExportData.indexOf('\n');
    const updatedData = tenantsExportData.substring(firstNewLineIndex + 1);

    const lines = updatedData.split('\n');

    lines.forEach((line) => {
      const values = line.split(',');

      if (values?.length > 1) {
        const createdDate = dayjs(values[6])?.isValid()
          ? dayjs(values[6]).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD');
        const deletedDate = dayjs(values[7])?.isValid()
          ? dayjs(values[7]).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD');
        const diffInDays = dayjs(deletedDate).diff(createdDate, 'd');

        const addColunmsValues = [
          values[2],
          values[3],
          values[0].replace('_', '-'),
          values[1],
          values[5],
          values[9],
          values[10],
          values[12],
          dayjs(values[11]).isValid() ? dayjs(values[11]).format('DD MMM YYYY :hh:mm a') : '---',
          dayjs(values[6]).isValid() ? dayjs(values[6]).format('DD MMM YYYY') : '---',
          diffInDays,
          `${values[14]}%`,
          `${values[13]}%`,
          `${values[15]}`,
          `${values[4]}`,
        ];
        csvData.push(addColunmsValues);
      }
    });

    const data: string[] = [
      fileName,
      generatedDate,
      filterData,
      colunms.join(','),
      csvData.join('\n'),
    ];

    exportCSV(saveFileName, data);
    dispatch(setResetTenantsExport(''));
  };

  useEffect(() => {
    if (fetchExportTenantsSuccessfully === true) {
      exportcsv();
    }
  }, [fetchExportTenantsSuccessfully]);

  return (
    <LayoutHOC searchDomain={setSearchString} actionType={actionType}>
      <Filter
        onDateChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        onChangeCriteria={onChangeCriteria}
        onChangeAllowedLicenses={onChangeAllowedLicenses}
        onClear={onClear}
        criteriaValue={criteria}
        allowLicensesValue={allowLicenses}
        onChangeStatus={onChangeStatus}
        tenantstatus={tenantstatus}
      />
      <div>
        RAG Overview
        <div className=' flex items-center justify-stretch gap-[20px] pb-5'>
          <div className='flex  flex-col w-1/2 gap-[20px]'>
            <div className='bg-white rounded-[6px] p-5 w-full h-28'>
              {fetchRAGOverviewLoading ? (
                <ProgressPercentageSkeleton />
              ) : (
                <div>
                  <div>RAG via created licenses = Login Count / Created License</div>
                  <span className='text-black font-bold text-2xl'>
                    {`${fetchRAGOverviewData?.loginCount} /${fetchRAGOverviewData?.createdLiecense}`}
                  </span>
                </div>
              )}
            </div>
            <div className='bg-white rounded-[6px] p-5 w-full h-28'>
              {fetchRAGOverviewLoading ? (
                <ProgressPercentageSkeleton />
              ) : (
                <div>
                  <div>RAG via allowed licenses = Login Count / Allowed License</div>
                  <span className='text-black font-bold text-2xl'>
                    {`${fetchRAGOverviewData?.loginCount} / ${fetchRAGOverviewData?.allowedLiecence}`}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='flex w-full gap-[20px]'>
            <div className='bg-white rounded-[6px] p-4 w-full'>
              {fetchRAGOverviewLoading ? (
                <PieNeedleRechartsSkeleton />
              ) : (
                <div>
                  <div className='text-black font-bold'>
                    Meter for RAG via created Licenses Usage
                    <span className='text-gray-400 float-right'>
                      <Tooltip title='Meter for RAG via created Licenses Usage'>
                        <InfoCircleFilled />
                      </Tooltip>
                    </span>
                  </div>

                  <span>Login Counts / Created Licenses</span>

                  <PieNeedleRecharts
                    chartData={getChartData('LU')}
                    perValue={getPercentage(
                      fetchRAGOverviewData?.loginCount,
                      fetchRAGOverviewData?.createdLiecense,
                    )}
                  />
                </div>
              )}
            </div>
            <div className='bg-white rounded-[6px] p-4 w-full'>
              {fetchRAGOverviewLoading ? (
                <PieNeedleRechartsSkeleton />
              ) : (
                <div>
                  <div className='text-black font-bold'>
                    Meter for RAG via allowed users
                    <span className='text-gray-400 float-right'>
                      <Tooltip title='Meter for RAG via allowed users'>
                        <InfoCircleFilled />
                      </Tooltip>
                    </span>
                  </div>

                  <span>Login Count / Allowed Licenses</span>
                  <PieNeedleRecharts
                    chartData={getChartData('LC')}
                    perValue={getPercentage(
                      fetchRAGOverviewData?.loginCount,
                      fetchRAGOverviewData?.allowedLiecence,
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <span className='pb-5'> Click on the below tabs to see the recspective RAG data</span>
        <div className='flex items-center justify-stretch gap-[20px] pb-5 mt-2'>
          <div className='flex  w-full gap-[20px]'>
            <div
              className={` rounded-[6px] p-4 w-full cursor-pointer  ${
                ragStatus === 'All' ? 'bg-indigo-100' : 'bg-white'
              } ${fetchRAGOverviewData.allCount <= 0 || fetchTenantsLoading ? 'ragDisable' : ''}`}
              onClick={() => {
                fetchRAGOverviewData.allCount <= 0 || fetchRAGOverviewLoading || fetchTenantsLoading
                  ? null
                  : onClickRAGStatus('All');
              }}
            >
              {fetchRAGOverviewLoading ? (
                <RAGCountSkeleton />
              ) : (
                <RAGDataCount
                  name='All'
                  btnName={'All Customers'}
                  text={'Click to see all customers'}
                  value={fetchRAGOverviewData.allCount}
                  perValue={null}
                />
              )}
            </div>
            <div
              className={` rounded-[6px] p-4 w-full cursor-pointer  ${
                ragStatus === 'RED' ? 'bg-indigo-100' : 'bg-white'
              } ${fetchRAGOverviewData.redCount <= 0 || fetchTenantsLoading ? 'ragDisable' : ''}`}
              onClick={() => {
                fetchRAGOverviewData.redCount <= 0 || fetchRAGOverviewLoading || fetchTenantsLoading
                  ? null
                  : onClickRAGStatus('RED');
              }}
            >
              {fetchRAGOverviewLoading ? (
                <RAGCountSkeleton />
              ) : (
                <RAGDataCount
                  name='RED'
                  btnName={'RED Customers'}
                  text={'Click to see who need attention'}
                  value={fetchRAGOverviewData.redCount}
                  perValue={null}
                />
              )}
            </div>
            <div
              className={` rounded-[6px] p-4 w-full cursor-pointer  ${
                ragStatus === 'AMBER' ? 'bg-indigo-100' : 'bg-white'
              } ${fetchRAGOverviewData.amberCount <= 0 || fetchTenantsLoading ? 'ragDisable' : ''}`}
              onClick={() => {
                fetchRAGOverviewData.amberCount <= 0 ||
                fetchRAGOverviewLoading ||
                fetchTenantsLoading
                  ? null
                  : onClickRAGStatus('AMBER');
              }}
            >
              {fetchRAGOverviewLoading ? (
                <RAGCountSkeleton />
              ) : (
                <RAGDataCount
                  name='AMBER'
                  btnName={'AMBER Customers'}
                  text={'Click to see who are potentially lost'}
                  value={fetchRAGOverviewData.amberCount}
                  perValue={null}
                />
              )}
            </div>
            <div
              className={` rounded-[6px] p-4 w-full cursor-pointer   ${
                ragStatus === 'GREEN' ? 'bg-indigo-100' : 'bg-white'
              } ${
                fetchRAGOverviewData.greenCount <= 0 || fetchTenantsLoading ? 'ragDisable' : ''
              } `}
              onClick={() => {
                fetchRAGOverviewData.greenCount <= 0 ||
                fetchRAGOverviewLoading ||
                fetchTenantsLoading
                  ? null
                  : onClickRAGStatus('GREEN');
              }}
            >
              {fetchRAGOverviewLoading ? (
                <RAGCountSkeleton />
              ) : (
                <RAGDataCount
                  name='GREEN'
                  btnName={'GREEN Customers'}
                  text={'Click to see who are going great'}
                  value={fetchRAGOverviewData.greenCount}
                  perValue={null}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <TenantList
        loading={fetchTenantsLoading}
        tenantsData={Object.values(tenantsData)}
        setRowDetails={setTenantDetails}
        onPaginationChange={onPaginationChange}
        totalRecords={tenantTotalRecords}
        page={page}
        limit={limit}
        downloadCSV={getTenantsExportData}
        downloadLoading={fetchExportTenantsLoading}
        startDate={startDate}
        endDate={endDate}
      />

      <Drawer
        width={'calc(100vw - 220px)'}
        height={'100vh'}
        onClose={handleCancel}
        open={!!tenantDetails?.id}
        destroyOnClose
        title={tenantDetails?.name}
        extra={
          <Space>
            <Select
              className='w-64'
              options={ragOption}
              placeholder='RAG Criteria'
              onChange={onChangeCriteriaSingleTenant}
              value={singleTenantCriteria === '' ? null : singleTenantCriteria}
            />
          </Space>
        }
      >
        <TenantDrawer tenantDetails={tenantDetails} />
      </Drawer>
    </LayoutHOC>
  );
};

export default Tenants;
