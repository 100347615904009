import { Button } from 'antd';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import { RAGStatus } from '../../../types';

interface Props {
  name: RAGStatus;
  btnName: string;
  text: string;
  value: number;
  perValue: number | null;
}

const RAGDataCount = ({ name, btnName, text, value, perValue }: Props) => {
  const btnClass =
    name === 'All'
      ? 'bg-gray-400 text-white'
      : name === 'RED'
      ? 'bg-red-500 text-white'
      : name === 'AMBER'
      ? 'bg-amber-500 text-black'
      : name === 'GREEN'
      ? 'bg-green-600 text-white'
      : '';
  const percentDivClass =
    name === 'All'
      ? ''
      : name === 'RED'
      ? 'text-red-500'
      : name === 'AMBER'
      ? 'text-amber-500'
      : name === 'GREEN'
      ? 'text-green-500'
      : '';

  const iconClass =
    name === 'All'
      ? ''
      : name === 'RED'
      ? 'rounded-md p-1 mr-1 bg-red-100'
      : name === 'AMBER'
      ? 'rounded-md p-1 mr-1 bg-amber-100'
      : name === 'GREEN'
      ? ' rounded-md p-1 mr-1 bg-green-100'
      : '';
  return (
    <div className='flex flex-col'>
      <div className='flex w-full'>
        <div className='flex items-center justify-between min-w-full'>
          <Button className={`${btnClass}`} title={btnName} key={name}>
            {btnName}
          </Button>
          <div className='flex items-center '>
            <div className='text-[18px] font-bold'>{value}</div>
            <div className='flex items-center ml-2'>
              {perValue === null ? null : perValue > 50 ? (
                <div className={percentDivClass}>
                  <span className={iconClass}>
                    <RiseOutlined />
                  </span>
                  <span className='font-bold'>{perValue}%</span>
                </div>
              ) : (
                <div className={percentDivClass}>
                  <span className={iconClass}>
                    <FallOutlined />
                  </span>
                  <span className='font-bold'>{perValue}%</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='mt-[5px] text-sm'>{text}</div>
    </div>
  );
};

export default RAGDataCount;
