import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  fetchExportTenantsThunk,
  fetchTenantAttendanceTrendThunk,
  fetchTenantsThunk,
  fetchTenantUserCreationTrendThunk,
  fetchRAGOverviewThunk,
  fetchRAG7TrendThunk,
  fetchTenantUserListTrendThunk,
  fetchSingleTenantThunk,
} from './tenantsAPI';
import {
  CommonRequestQuery,
  Pagination,
  QueryResponse,
  Tenant,
  TenantAttendanceTrend,
  TenantsData,
  TenantUserCreationTrend,
  RAGOverviewTrend,
  RAG7Trend,
  TenantUserList,
  QueryResponseWithPagination,
} from '../../types';
import {
  initialPagination,
  initialRAGOverviewData,
  initialSingleTrendData,
} from '../../app/constants';
import { convertSnakeToCamel } from '../../app/utils';
import { RejectedActionFromAsyncThunk } from '@reduxjs/toolkit/dist/matchers';

export interface TenantsState {
  fetchTenantsLoading: boolean;
  tenantsData: TenantsData;
  fetchTenantUserCreationTrendLoading: boolean;
  tenantUserCreationTrend: Array<TenantUserCreationTrend>;
  fetchTenantAttendanceTrendLoading: boolean;
  tenantAttendanceTrend: Array<TenantAttendanceTrend>;
  tenantsErrorMessage?: string;
  pagination: Pagination;
  tenantAttendanceTrendPagination: Pagination;
  tenantUserCreationTrendPagination: Pagination;
  fetchExportTenantsLoading: boolean;
  fetchExportTenantsSuccessfully: boolean;
  tenantsExportData: string;
  tenantsExportErrorMessage?: string;
  tenantTotalRecords: number;
  fetchRAGOverviewLoading: boolean;
  fetchRAGOverviewSuccessfully: boolean;
  fetchRAGOverviewData: RAGOverviewTrend;
  fetchRAG7TrendLoading: boolean;
  fetchRAG7TrendSuccessfully: boolean;
  fetchRAG7TrendData: Array<RAG7Trend>;
  fetchTenantUserListLoading: boolean;
  fetchTenantUserListSuccessfully: boolean;
  fetchTenantUserListData: Array<TenantUserList>;
  fetchTenantUserListPagination: Pagination;
  fetchSingleTrendLoading: boolean;
  fetchSingleTrendData: Tenant;
}

const initialState: TenantsState = {
  fetchTenantsLoading: true,
  tenantsData: {},
  tenantsErrorMessage: '',
  fetchTenantUserCreationTrendLoading: false,
  tenantUserCreationTrend: [],
  fetchTenantAttendanceTrendLoading: false,
  tenantAttendanceTrend: [],
  pagination: initialPagination,
  tenantAttendanceTrendPagination: initialPagination,
  tenantUserCreationTrendPagination: initialPagination,
  fetchExportTenantsLoading: false,
  fetchExportTenantsSuccessfully: false,
  tenantsExportData: '',
  tenantsExportErrorMessage: '',
  tenantTotalRecords: 0,
  fetchRAGOverviewLoading: false,
  fetchRAGOverviewSuccessfully: false,
  fetchRAGOverviewData: initialRAGOverviewData,
  fetchRAG7TrendLoading: false,
  fetchRAG7TrendSuccessfully: false,
  fetchRAG7TrendData: [],
  fetchTenantUserListLoading: false,
  fetchTenantUserListSuccessfully: false,
  fetchTenantUserListData: [],
  fetchTenantUserListPagination: initialPagination,
  fetchSingleTrendLoading: false,
  fetchSingleTrendData: initialSingleTrendData,
};

export const fetchTenants = createAsyncThunk(
  'tenants/fetchTenants',
  async (query: CommonRequestQuery) => {
    const response = await fetchTenantsThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const fetchExportTenants = createAsyncThunk(
  'tenants/fetchExportTenants',
  async (query: CommonRequestQuery) => {
    const response = await fetchExportTenantsThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const fetchTenantUserCreationTrend = createAsyncThunk(
  'tenants/fetchTenantUserCreationTrend',
  async (query: CommonRequestQuery) => {
    const response = await fetchTenantUserCreationTrendThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const fetchTenantAttendanceTrend = createAsyncThunk(
  'tenants/fetchTenantAttendanceTrend',
  async (query: CommonRequestQuery) => {
    const response = await fetchTenantAttendanceTrendThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const fetchRAGOverview = createAsyncThunk(
  'tenants/fetchRAGOverview',
  async (query: CommonRequestQuery) => {
    const response = await fetchRAGOverviewThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const fetchRAG7Trend = createAsyncThunk(
  'tenants/fetchRAG7Trend',
  async (query: CommonRequestQuery) => {
    const response = await fetchRAG7TrendThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const fetchTenantUserList = createAsyncThunk(
  'tenants/fetchTenantUserList',
  async (query: CommonRequestQuery) => {
    const response = await fetchTenantUserListTrendThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const fetchSingleTenant = createAsyncThunk(
  'tenants/fetchSingleTenant',
  async (query: CommonRequestQuery) => {
    const response = await fetchSingleTenantThunk(query);
    return convertSnakeToCamel(response.data);
  },
);

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setTenantsErrorMessage: (state, action: PayloadAction<string>) => {
      state.tenantsErrorMessage = action.payload;
    },
    setResetTenantsExport: (state, action: PayloadAction<string>) => {
      state.tenantsExportErrorMessage = action.payload;
      state.fetchExportTenantsSuccessfully = false;
      state.tenantsExportData = '';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTenants.pending, (state) => {
        state.fetchTenantsLoading = true;
        state.pagination = initialPagination;
      })
      .addCase(fetchTenants.fulfilled, (state, action: PayloadAction<QueryResponse<Tenant>>) => {
        state.fetchTenantsLoading = false;
        state.tenantsData =
          Object.values(action?.payload?.data)?.length > 0
            ? action?.payload?.data?.reduce(
                (acc: TenantsData, tenant) => ({ ...acc, [tenant.id]: tenant }),
                {},
              )
            : {};
        state.tenantTotalRecords = action.payload?.optional;
      })
      .addCase(
        fetchTenants.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchTenants>) => {
          state.fetchTenantsLoading = false;
          state.tenantsData = {};
          state.tenantsErrorMessage = action.error.message;
        },
      )
      .addCase(fetchTenantUserCreationTrend.pending, (state) => {
        state.fetchTenantUserCreationTrendLoading = true;
        state.tenantUserCreationTrend = [];
        state.tenantUserCreationTrendPagination = initialPagination;
      })
      .addCase(
        fetchTenantUserCreationTrend.fulfilled,
        (state, action: PayloadAction<QueryResponse<TenantUserCreationTrend>>) => {
          state.fetchTenantUserCreationTrendLoading = false;
          state.tenantUserCreationTrend = action.payload.data;
          //    state.tenantUserCreationTrendPagination = action.payload.optional.pagination;
        },
      )
      .addCase(
        fetchTenantUserCreationTrend.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchTenantUserCreationTrend>) => {
          state.fetchTenantUserCreationTrendLoading = false;
          state.tenantsErrorMessage = action.error.message;
        },
      )
      .addCase(fetchTenantAttendanceTrend.pending, (state) => {
        state.fetchTenantAttendanceTrendLoading = true;
        state.tenantAttendanceTrend = [];
        state.tenantAttendanceTrendPagination = initialPagination;
      })
      .addCase(
        fetchTenantAttendanceTrend.fulfilled,
        (state, action: PayloadAction<QueryResponse<TenantAttendanceTrend>>) => {
          state.fetchTenantAttendanceTrendLoading = false;
          state.tenantAttendanceTrend = action.payload.data;
          // state.tenantAttendanceTrendPagination = action.payload.optional.pagination;
        },
      )
      .addCase(
        fetchTenantAttendanceTrend.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchTenantAttendanceTrend>) => {
          state.fetchTenantAttendanceTrendLoading = false;
          state.tenantsErrorMessage = action.error.message;
        },
      )
      .addCase(fetchExportTenants.pending, (state) => {
        state.fetchExportTenantsLoading = true;
        state.fetchExportTenantsSuccessfully = false;
        state.tenantsExportData = '';
      })
      .addCase(fetchExportTenants.fulfilled, (state, action) => {
        state.fetchExportTenantsLoading = false;
        state.fetchExportTenantsSuccessfully = true;
        state.tenantsExportData = action.payload;
      })
      .addCase(
        fetchExportTenants.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchExportTenants>) => {
          state.fetchExportTenantsLoading = false;
          state.fetchExportTenantsSuccessfully = false;
          state.tenantsExportErrorMessage = action.error.message;
          // state.tenantsExportData = {};
        },
      )
      .addCase(fetchRAGOverview.pending, (state) => {
        state.fetchRAGOverviewLoading = true;
        state.fetchRAGOverviewSuccessfully = false;
      })
      .addCase(
        fetchRAGOverview.fulfilled,
        (state, action: PayloadAction<QueryResponse<RAGOverviewTrend>>) => {
          state.fetchRAGOverviewLoading = false;
          state.fetchRAGOverviewData =
            Object.values(action?.payload?.data)?.length > 0
              ? { ...state.fetchRAGOverviewData, ...action?.payload?.data }
              : { ...initialRAGOverviewData };
          state.fetchRAGOverviewSuccessfully = true;
        },
      )
      .addCase(
        fetchRAGOverview.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchRAGOverview>) => {
          state.fetchRAGOverviewLoading = false;
          state.fetchRAGOverviewSuccessfully = false;
          state.fetchRAGOverviewData = initialRAGOverviewData;
          state.tenantsErrorMessage = action.error.message;
        },
      )

      .addCase(fetchRAG7Trend.pending, (state) => {
        state.fetchRAG7TrendLoading = true;
        state.fetchRAG7TrendSuccessfully = false;
      })
      .addCase(
        fetchRAG7Trend.fulfilled,
        (state, action: PayloadAction<QueryResponse<RAG7Trend>>) => {
          state.fetchRAG7TrendLoading = false;
          state.fetchRAG7TrendData = action?.payload?.data;
          state.fetchRAG7TrendSuccessfully = true;
        },
      )
      .addCase(
        fetchRAG7Trend.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchRAG7Trend>) => {
          state.fetchRAG7TrendLoading = false;
          state.fetchRAG7TrendSuccessfully = false;
          state.fetchRAG7TrendData = [];
          state.tenantsErrorMessage = action.error.message;
        },
      )
      .addCase(fetchTenantUserList.pending, (state) => {
        state.fetchTenantUserListLoading = true;
        state.fetchTenantUserListSuccessfully = false;
      })
      .addCase(
        fetchTenantUserList.fulfilled,
        (state, action: PayloadAction<QueryResponseWithPagination<TenantUserList>>) => {
          state.fetchTenantUserListLoading = false;
          state.fetchTenantUserListData = action?.payload?.data;
          state.fetchTenantUserListSuccessfully = true;
          state.fetchTenantUserListPagination = action?.payload.optional.pagination;
        },
      )
      .addCase(
        fetchTenantUserList.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchTenantUserList>) => {
          state.fetchTenantUserListLoading = false;
          state.fetchTenantUserListSuccessfully = false;
          state.tenantsErrorMessage = action.error.message;
        },
      )
      .addCase(fetchSingleTenant.pending, (state) => {
        state.fetchSingleTrendLoading = true;
      })
      .addCase(
        fetchSingleTenant.fulfilled,
        (state, action: PayloadAction<QueryResponse<Tenant>>) => {
          state.fetchSingleTrendLoading = false;
          state.fetchSingleTrendData = {
            ...state.fetchSingleTrendData,
            ...action?.payload?.data[0],
          };
        },
      )
      .addCase(
        fetchSingleTenant.rejected,
        (state, action: RejectedActionFromAsyncThunk<typeof fetchSingleTenant>) => {
          state.fetchSingleTrendLoading = false;
          state.tenantsErrorMessage = action.error.message;
        },
      );
  },
});

export const { setTenantsErrorMessage, setResetTenantsExport } = tenantsSlice.actions;

export const selectTenants = (state: RootState) => state.tenants;

export default tenantsSlice.reducer;
