import React, { ReactNode, ChangeEvent, useState, useCallback, useEffect } from 'react';
import { Input, Layout, Tooltip } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { AppstoreAddOutlined, InfoCircleFilled } from '@ant-design/icons';
import debounce from 'lodash/debounce';

interface Props {
  children: ReactNode;
  searchDomain: (searchValue: string) => void;
  actionType: string;
}

const LayoutHOC = ({ children, searchDomain, actionType }: Props) => {
  const [searchValue, setSearchValue] = useState('');

  const debouncedHandleChange = debounce(searchDomain, 300);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      debouncedHandleChange(e.target.value);
    },
    [searchDomain],
  );

  useEffect(() => {
    if (actionType === 'datePicker' || actionType === 'reset') setSearchValue('');
  }, [actionType]);

  return (
    <Layout>
      <Header className={`bg-white flex justify-start items-center gap-4 text-2xl font-bold`}>
        <AppstoreAddOutlined className='bg-amber-100 text-amber-500 rounded-md p-2 mr-2 rotate-[270deg]' />
        Customer Success Dashboard
        <span className='text-gray-400 float-right'>
          <Tooltip title='Customer Success Dashboard'>
            <InfoCircleFilled />
          </Tooltip>
        </span>
        <div className='pl-20'>
          <Input
            placeholder='Search by domain name'
            allowClear
            onChange={onInputChange}
            style={{ width: 600, height: 40 }}
            value={searchValue}
          />
        </div>
      </Header>

      <Content className='h-full py-4 px-8'>{children}</Content>
    </Layout>
  );
};

export default LayoutHOC;
