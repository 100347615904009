import React from 'react'

import { Route, Routes } from 'react-router-dom'
import { COUNTER } from './app/constants'
import Counter from './components/Counter'
import Tenants from './components/Tenants'

function App() {
  return (
    <Routes>
      <Route path={COUNTER} element={<Counter />} />
      <Route path='*' element={<Tenants />} />
    </Routes>
  )
}

export default App
