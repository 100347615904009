import { PieChart, Pie, Cell } from 'recharts';

interface pieChartData {
  name: string;
  value: number;
  color: string;
}

interface Props {
  chartData: Array<pieChartData>;
  perValue: number;
}

const PieNeedleRecharts = ({ chartData, perValue }: Props) => {
  const RADIAN = Math.PI / 180;
  const data = chartData;
  const cx = 200;
  const cy = 170;
  const iR = 120;
  const oR = 150;
  const value = perValue;

  const needle = (
    value: number,
    data: Array<pieChartData>,
    cx: number,
    cy: number,
    iR: number,
    oR: number,
    color: string,
  ) => {
    let total = 0;
    data.forEach((v) => {
      total += v?.value;
    });

    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke='none' key={'d'} />,
      <path
        key={'dd'}
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke='#none'
        fill={color}
      />,
    ];
  };

  return (
    <div>
      <PieChart width={380} height={180}>
        <text x={210} y={100} dy={8} textAnchor='middle' fill={'#000000'}>
          {`${perValue}%`}
        </text>

        <Pie
          dataKey='value'
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill='#8884d8'
          stroke='none'
          paddingAngle={4}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} width={50} />
          ))}
        </Pie>

        {needle(value, data, cx, cy, iR, oR, '#000000')}
      </PieChart>
    </div>
  );
};

export default PieNeedleRecharts;
