/* eslint-disable camelcase */
import { Button, Table, Tag, Tooltip } from 'antd';
import { Pagination, Tenant } from '../../../types';
import dayjs, { Dayjs } from 'dayjs';
import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../../app/hooks';
import {
  fetchRAG7Trend,
  fetchSingleTenant,
  fetchTenantAttendanceTrend,
  fetchTenantUserCreationTrend,
  fetchTenantUserList,
} from './../tenantsSlice';

interface Props {
  loading: boolean;
  tenantsData: Array<Tenant>;
  setRowDetails: (details: Tenant) => void;
  onPaginationChange: (page: number, limit: number, actionType: string) => void;
  pagination?: Pagination;
  page: number;
  totalRecords?: number;
  limit: number;
  downloadCSV?: () => void;
  downloadLoading: boolean;
  startDate?: null | Dayjs;
  endDate?: null | Dayjs;
}
const TenantList = ({
  loading,
  tenantsData,
  setRowDetails,
  onPaginationChange,
  totalRecords,
  page,
  limit,
  downloadCSV,
  downloadLoading,
  startDate,
  endDate,
}: Props) => {
  const dispatch = useAppDispatch();

  const columns: ColumnsType<Tenant> = [
    {
      title: 'Domain name',
      dataIndex: 'baseDomain',
      width: 200,
      fixed: 'left',
      ellipsis: { showTitle: false },
      render: (value) => (value?.length >= 30 ? <Tooltip title={value}>{value}</Tooltip> : value),
    },
    {
      title: 'Email ID',
      dataIndex: 'contactEmail',
      width: 250,
      fixed: 'left',
      ellipsis: { showTitle: false },
      render: (value) => (value?.length >= 35 ? <Tooltip title={value}>{value}</Tooltip> : value),
    },
    {
      title: 'Tenant ID',
      dataIndex: 'id',
      width: 200,
      render: (value) => value?.replaceAll('_', '-'),
    },

    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value) => {
        return value?.length >= 27 ? <Tooltip title={value}>{value}</Tooltip> : value;
      },
    },
    {
      title: 'Plan',
      dataIndex: 'currentPlan',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value) => {
        const index = value?.indexOf('-');
        const result = index !== -1 ? value.substring(14) : value;
        return <Tooltip title={value}>{result}</Tooltip>;
      },
    },
    // {
    //   title: 'Contact Number',
    //   dataIndex: 'phoneNumber',
    //   width: 150,
    // },

    {
      title: 'Allowed Licenses (A)',
      dataIndex: 'allowedLiecence',
      width: 100,
    },
    {
      title: 'Created Licenses (C)',
      dataIndex: 'createdLiecense',
      width: 100,
    },
    {
      title: 'Login Count (L)',
      dataIndex: 'loginCount',
      width: 110,
    },
    {
      title: 'Last Login Date',
      dataIndex: 'lastLoginDate',
      width: 180,

      render: (value) =>
        !isNaN(Date.parse(value)) ? dayjs(value).format('DD MMM YYYY :hh:mm a') : '---',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      width: 100,
      render: (value) => (!isNaN(Date.parse(value)) ? dayjs(value).format('DD MMM YYYY') : '---'),
    },
    {
      title: 'Active Days',
      dataIndex: 'activeDays',
      width: 100,
      render: (_, records) => {
        const createdDate = dayjs(records.createdDate)?.isValid()
          ? dayjs(records.createdDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD');
        const deletedDate = dayjs(records.deletedDate)?.isValid()
          ? dayjs(records.deletedDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD');
        const diffInDays = dayjs(deletedDate).diff(createdDate, 'd');
        return `${diffInDays} days`;
      },
    },
    {
      title: 'RAG via Allowed Licenses % (L/A)',
      dataIndex: 'licenseCovragePer',
      render: (val) => {
        return `${val}%`;
      },
      width: 130,
    },

    {
      title: 'RAG via Created licenses % (L/C)',
      dataIndex: 'licenseUsesPer',
      width: 130,
      render: (val) => {
        return `${val}%`;
      },
    },

    {
      title: 'RAG Status',
      dataIndex: 'ragStatus',
      width: 100,
      fixed: 'right',
      render: (val) => {
        const iconClass =
          val === 'All'
            ? ''
            : val === 'RED'
            ? 'text-white rounded-md p-1 mr-1 bg-red-500 w-full text-center'
            : val === 'AMBER'
            ? 'text-white rounded-md p-1 mr-1 bg-amber-500 w-full text-center'
            : val === 'GREEN'
            ? 'text-white rounded-md p-1 mr-1 bg-green-500 w-full text-center'
            : '';

        return <Tag className={iconClass}>{val}</Tag>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      fixed: 'right',
      render: (val) => {
        return val === 'ENABLED' ? (
          <Tag icon={<CheckCircleOutlined />} color='success'>
            {val}
          </Tag>
        ) : (
          <Tag icon={<CloseCircleOutlined />} color='error'>
            {val}
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <div className={`flex  justify-between items-center h-8 `}>
        <div className='flex justify-start items-center'>Tenant list</div>

        <div className='items-end pr-4 cursor-pointer'>
          <Button
            type='default'
            icon={<DownloadOutlined />}
            loading={downloadLoading}
            onClick={downloadCSV}
            disabled={loading || tenantsData?.length <= 0}
          />
        </div>
      </div>

      <div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={tenantsData}
          size='small'
          onRow={(tenant) => ({
            onClick: () => {
              dispatch(
                fetchTenantUserCreationTrend({
                  limit: 1000,
                  page: 1,
                  tenantId: tenant.id?.replaceAll('_', '-'),
                  startDate: dayjs().subtract(1, 'y').format('YYYY-MM-DD') + 'T00:00:00.034Z',
                  endDate: dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z',
                }),
              );
              dispatch(
                fetchTenantAttendanceTrend({
                  limit: 1000,
                  page: 1,
                  tenantId: tenant.id?.replaceAll('_', '-'),
                  startDate: dayjs().subtract(30, 'd').format('YYYY-MM-DD') + 'T00:00:00.034Z',
                  endDate: dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z',
                }),
              );
              dispatch(
                fetchRAG7Trend({
                  limit: 0,
                  page: 1,
                  rag_type: 'license_uses_per',
                  rag_summary: false,
                  tenant_id: tenant.id?.replaceAll('_', '-'),
                  startDate: dayjs().subtract(7, 'd').format('YYYY-MM-DD') + 'T00:00:00.034Z',
                  endDate: dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z',
                }),
              );
              dispatch(
                fetchTenantUserList({
                  limit: 15,
                  page: 1,
                  tenant_id: tenant.id?.replaceAll('_', '-'),
                  startDate: dayjs().format('YYYY-MM-DD') + 'T00:00:00.034Z',
                  endDate: dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z',
                  sort_by: 'user_name',
                  desc: false,
                }),
              );
              dispatch(
                fetchSingleTenant({
                  limit: 0,
                  page: 0,
                  startDate: dayjs().subtract(5, 'y').format('YYYY-MM-DD') + 'T00:00:00.034Z',
                  endDate: dayjs().format('YYYY-MM-DD') + 'T23:59:59.034Z',
                  base_domain: tenant.baseDomain,
                }),
              );
              setRowDetails(tenant);
            },
            className: 'cursor-pointer',
          })}
          pagination={{
            showTotal: (total) => `Total ${total} Projects`,
            total: totalRecords,
            defaultPageSize: 20,
            pageSizeOptions: [20, 50, 100, 200],
            current: page,
            pageSize: limit,
            showSizeChanger: true,
            onChange: (page, limit) => onPaginationChange(page, limit, 'pagination'),
          }}
          scroll={{ x: 1300 }}
        />
      </div>
    </>
  );
};

export default TenantList;
